.thermal_inv {
    width: 315px ;
    border: 1px solid #cccccca6;
    border-left: 0;
}
.thermal_box {
    padding: 3px 11px;
    text-align: center;
    border-bottom: 1px solid #cccccc8f;
    margin: 0 0 6px;
}
.thermal_box img {
    display: block;
    margin: 5px auto 4px;
    width: 66px;
    height: 66px;
    object-fit: contain;
}
.thermal_box b {
    font-size: 19px;
    display: block;
    margin: 0 0 3px;
}
.thermal_box p {
    font-size: 12px;
    margin: 0 0 2px;
}
.user_info {
    padding: 0 6px 5px;
}
.user_info p {
    margin: 0 0 1px;
    font-size: 12px;
}
.user_info b {
    width: 40%;
    display: inline-block;
    text-align: right;
    margin: 0 3px 5px 0;
    font-size: 14px;
    font-weight: bold;
}
.table_invoice table {
    border-collapse: collapse;
    width: 100%;
    margin: 0;
}
.table_invoice td, .table_invoice th {
    text-align: left;
    padding: 3px 6px;
    font-size: 13px;
    font-weight: 600;
}
.table_invoice tr:nth-child(even) {
  background-color: #f5f5f5;
  -webkit-print-color-adjust: exact;
}
.table_invoice th{
    background: #000;
    color: #fff;
    -webkit-print-color-adjust: exact;
}
.table_invoice{
    border-bottom: 2px solid #000;
}
.subtotal {
    width: 240px;
    float: right;
}
.subtotal p {
    margin: 0;
    font-size: 14px;
    border-bottom: 2px solid #000;
    padding: 3px 9px 3px 10px;
}
.subtotal p b {
    width: 67%;
    display: inline-block;
    text-align: right;
    margin: 0 5px 0 0;
}
.thankyou {
    clear: both;
    border-top: 1px solid #ccc;
    padding: 1px 0 4px;
    text-align: center;
}
.thankyou p {
    font-weight: 600;
    margin: 0 0 3px;
    font-size: 10px;
}
.thankyou p span {
    color: #0088cc;
}
.subtotal p:last-child{
    border-bottom: none;
}
@media print {
    .thermal_inv {
        width: 315px !important;
        border: 1px solid #cccccca6;
        border-left: 0;
    }
    .table_invoice th{
        background: #000 !important;
        color: #fff !important;
        -webkit-print-color-adjust: exact !important;
    }
    .table_invoice tr:nth-child(even) {
      background: #f5f5f5 !important;
      -webkit-print-color-adjust: exact !important;
    }
}


